import {
  axios,
  axiosWithUnauthorisedMiddleware,
  axiosWithSentryMiddleware
} from '@nm-utils-lib-web/axios-custom-middleware';

const NonAuthenticatedUserService = axiosWithSentryMiddleware(
  axiosWithUnauthorisedMiddleware(
    axios.create({
      baseURL: global.NutmegConfig.NM_LEGACY_USER_SERVICE,
      timeout: 30000,
      headers: { 'API-CLIENT': 'WEB' }
    })
  )
);

export default NonAuthenticatedUserService;
