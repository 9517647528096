import Translations, { getCommonTranslations } from '@nm-utils-lib-web/translations';
import { getOrganisation, ORGANISATIONS } from '@nm-utils-lib-web/organisations';
import { nutmeg as nutmegPot, johnlewisfinanceGBR as johnLewisFinanceGBRPot } from '@nm-pot/languages';
import merge from 'lodash/merge';

import { nutmeg as nutmegSignup, johnlewisfinanceGBR as jlfSignup, chaseGBR as chaseSignup } from '../languages';

export default new Translations({
  language: 'en',
  defaultNS: getOrganisation(),
  resources: {
    en: {
      default: {
        ...getCommonTranslations(ORGANISATIONS.NUTMEG_GBR_BIGBEAR),
        ...nutmegSignup,
        ...nutmegPot,
        ...nutmegPot
      },
      [ORGANISATIONS.JOHN_LEWIS_FINANCE_GBR]: {
        ...getCommonTranslations(ORGANISATIONS.JOHN_LEWIS_FINANCE_GBR),
        ...merge(
          {},
          {
            ...nutmegSignup,
            ...johnLewisFinanceGBRPot,
            ...johnLewisFinanceGBRPot
          },
          jlfSignup
        )
      },
      [ORGANISATIONS.NUTMEG_GBR_BIGBEAR]: {
        ...getCommonTranslations(ORGANISATIONS.NUTMEG_GBR_BIGBEAR),
        ...nutmegSignup,
        ...nutmegPot,
        ...nutmegPot
      },
      [ORGANISATIONS.CHASE_GBR]: {
        ...getCommonTranslations(ORGANISATIONS.CHASE_GBR),
        ...merge(
          {},
          {
            ...nutmegSignup,
            ...nutmegPot,
            ...nutmegPot
          },
          chaseSignup
        )
      }
    }
  }
});
