import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { environments } from '@nm-utils-lib-web/environments';
import { monitor, apps, getAppName } from '@nm-utils-lib-web/monitoring';
import { FlagsProvider } from '@nm-utils-lib-web/flags';
import { AuthProvider } from '@nm-utils-lib-web/authentication';
import Loading from '@nutkit/component-loading';

import App from './components/App';
import './helpers/i18n';

const app = apps.NM_SIGNUP_WEB;
const environment = window.NutmegConfig.ENVIRONMENT;
const version = window.NutmegConfig.VERSION;
const isLocalEnvironment = environment === environments.LOCAL;
const root = createRoot(document.getElementById('root'));

monitor.init({
  app: getAppName(app),
  disabled: isLocalEnvironment,
  environment,
  release: `${app}@${version}`,
  tracingSampleRate: parseFloat(window.NutmegConfig.SENTRY_SAMPLE_RATE)
});

root.render(
  <AuthProvider renderBlocking>
    <FlagsProvider renderBlocking={false}>
      <Router>
        <Suspense fallback={<Loading />}>
          <App />
        </Suspense>
      </Router>
    </FlagsProvider>
  </AuthProvider>
);
