import React from 'react';
import { bool, string, func } from 'prop-types';
import { Layout } from '@nutkit/layouts';
import Section from '@nutkit/component-section';
import { List, ListItem, listItemStackSpacing } from '@nutkit/component-list';
import { IconTick, IconDot, iconColors } from '@nutkit/component-icon';
import Align, { justifyContentRules, alignItemsRules } from '@nutkit/component-align';
import Link from '@nutkit/component-link';
import {
  Heading,
  headingLevels,
  headingStyles,
  Text,
  textWeights,
  textColors,
  tagNames,
  textAligns
} from '@nutkit/component-text';
import Panel from '@nutkit/component-panel';
import { SupportArticles } from '@nm-utils-lib-web/routes';
import { Trans, useTranslation } from '@nm-utils-lib-web/translations';
import { isJohnLewisFinanceGBR } from '@nm-utils-lib-web/organisations';

import styles from './RegisterBanner.module.scss';

const TRANSLATION_NAMESPACE = 'signup.registerPage.register';

const getBannerStyles = (isMobile, isJohnLewisFinance) => {
  if (isJohnLewisFinance) {
    return {
      textColor: textColors.BLACK,
      textAlign: isMobile ? textAligns.LEFT : textAligns.CENTER,
      bulletColor: iconColors.BLACK,
      headingStyle: headingStyles.TITLE_1,
      icon: IconTick
    };
  }

  return {
    textColor: isMobile ? textColors.BLACK : textColors.WHITE,
    textAlign: textAligns.LEFT,
    bulletColor: iconColors.SUCCESS,
    headingStyle: headingStyles.HIGHLIGHT,
    icon: IconDot
  };
};

const RequiredItemList = ({ textColor, textAlign, icon, bulletColor }) => {
  const { t } = useTranslation();
  const listItems = ['listItem1', 'listItem2', 'listItem3', 'listItem4', 'listItem5', 'listItem6'];

  return (
    <>
      <Text textWeight={textWeights.BOLD} textColor={textColor} textAlign={textAlign}>
        {t(`${TRANSLATION_NAMESPACE}.listTitle`)}
      </Text>
      <List icon={icon} bulletColor={bulletColor} space={listItemStackSpacing.SM}>
        {listItems.map(item => (
          <ListItem key={item}>
            <Text tagName={tagNames.SPAN} textColor={textColor}>
              {t(`${TRANSLATION_NAMESPACE}.${item}`)}
            </Text>
          </ListItem>
        ))}
      </List>
    </>
  );
};

RequiredItemList.propTypes = {
  textColor: string.isRequired,
  textAlign: string.isRequired,
  icon: func.isRequired,
  bulletColor: string.isRequired
};

const RegisterBanner = ({ isMobile }) => {
  const { t } = useTranslation();
  const isJohnLewisFinance = isJohnLewisFinanceGBR();
  const bannerStyles = getBannerStyles(isMobile, isJohnLewisFinance);

  return (
    <>
      {isMobile ? (
        <Panel>
          <RequiredItemList {...bannerStyles} />
        </Panel>
      ) : (
        <Section
          data-qa="register-banner-desktop"
          className={isJohnLewisFinance ? styles.jlfContainer : styles.container}
        >
          <Align alignItems={alignItemsRules.CENTER} justifyContent={justifyContentRules.CENTER} fullHeight>
            <Layout>
              <Heading
                level={headingLevels.TWO}
                headingStyle={bannerStyles.headingStyle}
                textColor={bannerStyles.textColor}
                textAlign={bannerStyles.textAlign}
              >
                {t(`${TRANSLATION_NAMESPACE}.heading`)}
              </Heading>
              <Text textColor={bannerStyles.textColor} textAlign={bannerStyles.textAlign}>
                {t(`${TRANSLATION_NAMESPACE}.text`)}
              </Text>
              <RequiredItemList {...bannerStyles} />
              <Text textColor={bannerStyles.textColor} textAlign={bannerStyles.textAlign}>
                <Trans
                  i18nKey={`${TRANSLATION_NAMESPACE}.supportNote`}
                  components={[<Link noColor isExternal href={SupportArticles.CONTACT_FAQ_URL} />]}
                />
              </Text>
            </Layout>
          </Align>
        </Section>
      )}
    </>
  );
};

RegisterBanner.propTypes = {
  isMobile: bool.isRequired
};

export default RegisterBanner;
