import React from 'react';
import { bool } from 'prop-types';
import { OrganisationFooter } from '@nm-ui-shared-lib-web/organisation-footer';
import ThemeProvider, { themeNames } from '@nutkit/theme';

export const Footer = ({ noStack }) => (
  <ThemeProvider themeName={themeNames.DARK}>
    <OrganisationFooter translationNameSpace="signup.common.footer" showAppDownload noStack={noStack} />
  </ThemeProvider>
);

Footer.propTypes = {
  noStack: bool
};

Footer.defaultProps = {
  noStack: false
};

export default Footer;
