import React from 'react';
import { Redirect } from 'react-router-dom';
import Loading from '@nutkit/component-loading';
import { useGetAuthenticationMetadata } from '@nm-utils-lib-web/authentication';
import { Flags, useVariant, Variants } from '@nm-utils-lib-web/flags';
import { useOnboardingStatus, useCustomerNextStep, STEPS, STATES } from '@nm-utils-lib-web/onboarding-journey-service';
import {
  CUSTOMER_MARKETING_CONSENTS_PATH,
  INVESTMENT_EXPERIENCE_PATH,
  APP_DOWNLOAD_PATH
} from '@nm-utils-lib-web/routes/customer';
import { useHandleError } from '@nm-utils-lib-web/error-boundary';

import useGetUser from '../../hooks/useGetUser';

import SideMenu from './SideMenu';
import NonInvestorDashboardLayout from './NonInvestorDashboardLayout';
import SidebarPanel from './SidebarPanel';
import MainPanel from './MainPanel';

const NonInvestorDashboardPage = () => {
  const { customerUuid } = useGetAuthenticationMetadata();
  const { triggerError } = useHandleError();
  const appDownloadVariantObject = useVariant(Flags.APP_DOWNLOAD);
  const {
    data: userDetails,
    isLoading: userDetailsLoading,
    error: userDetailsError
  } = useGetUser({
    customerUuid
  });
  const {
    data: status,
    isLoading: statusLoading,
    error: statusError
  } = useOnboardingStatus({
    customerId: customerUuid,
    onError: triggerError
  });
  const {
    data: nextStep,
    isLoading: nextStepLoading,
    error: nextStepError
  } = useCustomerNextStep({
    customerId: customerUuid,
    currentStep: STEPS.NON_INVESTOR_DASHBOARD,
    params: {
      appDownloadVariant: appDownloadVariantObject.name
    }
  });
  const dashboardLoading = statusLoading || nextStepLoading;
  const dashboardError = statusError || nextStepError;

  if (dashboardLoading) return <Loading />;

  if (status?.needs.includes(STATES.NEEDS_MARKETING_CONSENTS)) {
    const redirectURL =
      appDownloadVariantObject?.name === Variants.VARIANT_D && !userDetails?.wasJisa
        ? APP_DOWNLOAD_PATH
        : CUSTOMER_MARKETING_CONSENTS_PATH;

    return <Redirect to={redirectURL} />;
  }

  if (nextStep === STEPS.INVESTOR_EXPERIENCE) {
    return <Redirect to={INVESTMENT_EXPERIENCE_PATH} />;
  }

  return (
    <NonInvestorDashboardLayout
      sideMenu={
        <SideMenu
          userDetails={userDetails}
          userDetailsError={Boolean(userDetailsError)}
          userDetailsLoading={userDetailsLoading}
        />
      }
      main={<MainPanel nextStep={nextStep} status={status} />}
      sidebar={
        <SidebarPanel
          blockRendering={dashboardError}
          wasJisa={userDetails?.wasJisa}
          nextStep={nextStep}
          status={status}
        />
      }
    />
  );
};

export default NonInvestorDashboardPage;
