import * as Yup from 'yup';

const getFirstValidationError = (value, rules) =>
  [...rules.publicPasswordRules, ...rules.privatePasswordRules].find(({ pattern, scope }) => {
    const result = pattern.test(value);

    // Because the BE returns regexs that evaluate differently based on whether they're 'private' or 'public'
    return scope === 'private' ? result : !result;
  });

export const registerFormValidationSchema = Yup.object().shape({
  email: Yup.string().email({ key: 'fields.email.invalid' }).required({ key: 'fields.email.required' }),
  password: Yup.string()
    .required({ key: 'fields.password.required' })
    .when('passwordRules', (passwordRules, schema) =>
      schema.test({
        name: 'isValid',
        test: (password, ctx) => {
          const firstValidationError = getFirstValidationError(password, passwordRules);

          if (firstValidationError) {
            return ctx.createError({
              message: firstValidationError?.message
            });
          }

          return true;
        }
      })
    )
});
