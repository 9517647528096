import cookies from 'browser-cookies';
import { ORGANISATIONS, getOrganisation } from '@nm-utils-lib-web/organisations';

const PROVIDER = 'rakuten';
const AFFILIATE_ID = 'affiliateId';
const AFFILIATE_LANDING_TIME = 'affiliateLandingTime';
const SUPPORTED_ORGS = [ORGANISATIONS.NUTMEG_GBR_BIGBEAR, ORGANISATIONS.CHASE_GBR];

const getCustomerAffiliateDetails = ({ enabled, currentUrl = document.location.href }) => {
  const url = new URL(currentUrl);
  const queryParams = new URLSearchParams(url.search);
  const affiliateId = cookies.get(AFFILIATE_ID) || queryParams.get(AFFILIATE_ID);
  const landingTime = cookies.get(AFFILIATE_LANDING_TIME) || queryParams.get(AFFILIATE_LANDING_TIME);

  if (!enabled) {
    return null;
  }

  if (!SUPPORTED_ORGS.includes(getOrganisation())) {
    return null;
  }

  if (!affiliateId) {
    return null;
  }

  if (!landingTime) {
    return null;
  }

  return {
    id: affiliateId,
    landingTime: parseInt(landingTime),
    provider: PROVIDER
  };
};

export default getCustomerAffiliateDetails;
