import { AuthorisedAPIServiceWithSentryMiddleware } from '@nm-utils-lib-web/authorised-api-service';

/** @type {import('./LegacyUserService.types').LegacyUserService} */
const LegacyUserService = AuthorisedAPIServiceWithSentryMiddleware({
  baseURL: global.NutmegConfig.NM_LEGACY_USER_SERVICE,
  headers: {
    'API-CLIENT': 'WEB',
    'API-CONSUMER': 'MOBILE'
  }
});

export default LegacyUserService;
