import React from 'react';
import { string, arrayOf, shape, bool } from 'prop-types';
import { STEPS, STATES } from '@nm-utils-lib-web/onboarding-journey-service';
import { useGetKycCheckAll } from '@nm-customer/common/hooks/useGetKycCheckAll';
import { useHandleError } from '@nm-utils-lib-web/error-boundary';
import { useGetAuthenticationMetadata } from '@nm-utils-lib-web/authentication';
import { StatusCodes } from 'http-status-codes';

import { checkCompletedStatus } from '../../../helpers/statusHelpers/checkStepCompletedStatus';
import { STEP_IMAGES } from '../../../constants/images';
import { PERSONAL_AND_FINANCIAL_DETAILS_STEPS } from '../../../constants/NonInvestorConstants';
import { getImageUrl } from '../../../helpers/getImageUrl';
import { shouldShowPotPanel } from '../../../helpers/statusHelpers/statusHelpers';

import SidebarCard from './SidebarCard/SidebarCard';
import { WealthServicesCard } from './WealthServicesCard';

const AFFORDABILITY_TRANSLATION_NAMESPACE = 'signup.nonInvestor.content.affordabilityPanel';
const RISK_TRANSLATION_NAMESPACE = 'signup.nonInvestor.content.riskPanel';
const PERSONAL_DETAILS_TRANSLATION_NAMESPACE = `signup.nonInvestor.content.personalDetailsPanel`;
const POT_TRANSLATION_NAMESPACE = `signup.nonInvestor.content.investmentPotPanel`;

/** @type {SidebarPanel} */
const SidebarPanel = ({ blockRendering, nextStep, status, wasJisa }) => {
  const hidePotPanel = wasJisa && !shouldShowPotPanel(status);
  const hideAffordabilityPanel = wasJisa;
  const { triggerError } = useHandleError();
  const { customerUuid } = useGetAuthenticationMetadata();
  const { data } = useGetKycCheckAll({
    customerUuid,
    onError: error => {
      if (error?.response?.status !== StatusCodes.NOT_FOUND) {
        return triggerError(error);
      }
    }
  });
  const hasComplianceFailed = data?.overallStatus && data?.overallStatus !== 'PASS';

  if (blockRendering) {
    return null;
  }

  return (
    <>
      {!PERSONAL_AND_FINANCIAL_DETAILS_STEPS.includes(nextStep) && (
        <SidebarCard
          data-qa="personal-details-panel"
          hasComplianceFailed={hasComplianceFailed}
          translationNamespace={PERSONAL_DETAILS_TRANSLATION_NAMESPACE}
          isStepCompleted={checkCompletedStatus({ status, hasState: STATES.HAS_FINANCIAL_DETAILS })}
          imageUrl={getImageUrl(STEP_IMAGES.PERSONAL_DETAILS)}
        />
      )}
      {nextStep !== STEPS.RISK_ASSESSMENT && (
        <SidebarCard
          data-qa="risk-panel"
          translationNamespace={RISK_TRANSLATION_NAMESPACE}
          isStepCompleted={checkCompletedStatus({ status, hasState: STATES.HAS_RISK_PROFILE })}
          imageUrl={getImageUrl(STEP_IMAGES.RISK_ASSESSMENT)}
        />
      )}
      {nextStep !== STEPS.AFFORDABILITY && !hideAffordabilityPanel && (
        <SidebarCard
          data-qa="affordability-sidebar"
          translationNamespace={AFFORDABILITY_TRANSLATION_NAMESPACE}
          isStepCompleted={checkCompletedStatus({ status, hasState: STATES.HAS_AFFORDABILITY })}
          imageUrl={getImageUrl(STEP_IMAGES.AFFORDABILITY)}
        />
      )}
      {nextStep !== STEPS.CREATE_POT && !hidePotPanel && (
        <SidebarCard
          data-qa="investment-pot-panel"
          translationNamespace={POT_TRANSLATION_NAMESPACE}
          isStepCompleted={checkCompletedStatus({ status, hasState: STATES.HAS_CONFIRMED_POT })}
          imageUrl={getImageUrl(STEP_IMAGES.INVESTMENT_POT)}
        />
      )}
      <WealthServicesCard />
    </>
  );
};

SidebarPanel.propTypes = {
  blockRendering: bool,
  nextStep: string.isRequired,
  status: shape({ has: arrayOf(string), needs: arrayOf(string) }).isRequired,
  wasJisa: bool
};

SidebarPanel.defaultProps = {
  blockRendering: false,
  wasJisa: false
};

export default SidebarPanel;
