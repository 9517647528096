import React from 'react';
import { string, bool } from 'prop-types';
import Panel from '@nutkit/component-panel';
import { Text, Heading, headingLevels } from '@nutkit/component-text';
import Thumbnail, { thumbnailSizes } from '@nutkit/component-thumbnail';
import Image from '@nutkit/component-image';
import Inline, { inlineSpaces, alignItemsRules } from '@nutkit/component-inline';
import Icon, { IconCheckCircle, iconColors, iconSizes } from '@nutkit/component-icon';
import { useTranslation } from '@nm-utils-lib-web/translations';
import Notification, { notificationLevels } from '@nutkit/component-notification';

import styles from './SidebarCard.module.scss';

const PROCESSING_TRANSLATION_NAMESPACE = 'signup.processingInfo';

/** @type {SidebarCard} */
const SidebarCard = ({ 'data-qa': dataQa, isStepCompleted, translationNamespace, hasComplianceFailed, imageUrl }) => {
  const { t } = useTranslation();

  return (
    <Panel data-qa={`${dataQa}-sidebar`}>
      <Heading level={headingLevels.THREE}>
        <Inline space={inlineSpaces.SM} alignItems={alignItemsRules.START}>
          <Thumbnail size={thumbnailSizes.XS}>
            <Image src={imageUrl} aria-hidden alt="" />
          </Thumbnail>
          {t(`${translationNamespace}.title`)}
          {isStepCompleted && (
            <Icon
              data-qa={`${dataQa}-icon-completed`}
              component={IconCheckCircle}
              size={iconSizes.MD}
              isTicked
              title={t(`${translationNamespace}.completed`)}
              className={styles.checkCircleCompleted}
              color={iconColors.DEFAULT}
            />
          )}
        </Inline>
      </Heading>
      {hasComplianceFailed ? (
        <Notification
          level={notificationLevels.INFORMATION}
          dismissable={false}
          data-qa={`${dataQa}-asking-notification`}
        >
          {t(`${PROCESSING_TRANSLATION_NAMESPACE}.panel.text.topText`)}
        </Notification>
      ) : (
        <Text>{t(`${translationNamespace}.description`)}</Text>
      )}
    </Panel>
  );
};

SidebarCard.propTypes = {
  'data-qa': string,
  isStepCompleted: bool,
  translationNamespace: string.isRequired,
  hasComplianceFailed: bool,
  imageUrl: string.isRequired
};

SidebarCard.defaultProps = {
  'data-qa': 'panel',
  isStepCompleted: false,
  hasComplianceFailed: false
};

export default SidebarCard;
