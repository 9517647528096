import * as WRAPPER_TYPES from '@nm-pot/common/constants/wrapperTypes';

export const getFirstEligibleDraft = ({ eligibleDrafts, hasJisaDependants, isJISAEligible, jisaDependants }) => {
  if (eligibleDrafts?.length > 0) {
    return eligibleDrafts[0];
  }
  if (hasJisaDependants && isJISAEligible) {
    return {
      ...jisaDependants[0],
      childUuid: jisaDependants[0].customerId,
      wrapperType: WRAPPER_TYPES.JISA,
      productType: WRAPPER_TYPES.JISA
    };
  }
};
