import React from 'react';
import { func, bool } from 'prop-types';
import Button, { buttonSizes, buttonCtaTypes } from '@nutkit/component-button';
import { Heading, Text, headingLevels } from '@nutkit/component-text';
import { Modal } from '@nutkit/component-modal';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { Trans, useTranslation } from '@nm-utils-lib-web/translations';
import Link from '@nutkit/component-link';
import { SupportArticles } from '@nm-utils-lib-web/routes';

const TRANSLATION_NAMESPACE = 'signup.nonInvestor.content.modal';
const dataQa = 'delete-draft-pot-modal';

export const DeleteDraftPotModal = ({ onConfirm, isOpen, onToggle }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <>
        <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.heading`)}</Heading>
        <Text>{t(`${TRANSLATION_NAMESPACE}.description`)}</Text>
        <Text>
          <Trans
            i18nKey={`${TRANSLATION_NAMESPACE}.support`}
            components={[
              <Link isExternal href={SupportArticles.CONTACT_URL}>
                {' '}
              </Link>
            ]}
          />
        </Text>
        <ButtonGroup align={buttonGroupAlignments.RIGHT} stackUp stack>
          <Button onClick={onToggle} size={buttonSizes.SM} cta={buttonCtaTypes.SECONDARY}>
            {t(`${TRANSLATION_NAMESPACE}.cancelButton`)}
          </Button>
          <Button
            onClick={() => {
              onConfirm();
              onToggle();
            }}
            size={buttonSizes.SM}
            data-qa={`${dataQa}-delete-button`}
          >
            {t(`${TRANSLATION_NAMESPACE}.confirmButton`)}
          </Button>
        </ButtonGroup>
      </>
    </Modal>
  );
};

DeleteDraftPotModal.propTypes = {
  onConfirm: func.isRequired,
  onToggle: func.isRequired,
  isOpen: bool
};

DeleteDraftPotModal.defaultProps = {
  isOpen: false
};
