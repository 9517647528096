import React from 'react';
import NoMatch from '@nm-utils-lib-web/no-match';

import OnboardingLayout from '../../layouts/OnboardingLayout';

const NoMatch404 = props => {
  return (
    <OnboardingLayout noActionLogo>
      <NoMatch {...props} translationNamespace="signup.common.page404" />
    </OnboardingLayout>
  );
};

export default NoMatch404;
