import uniqBy from 'lodash/uniqBy';
import { getProductByWrapper } from '@nm-pot/common/helpers/getProductByWrapper';
import * as WRAPPER_TYPES from '@nm-pot/common/constants/wrapperTypes';

const hasValidWrapperIntent = ({ wrapperIntent }) => wrapperIntent && wrapperIntent.key !== WRAPPER_TYPES.GIA_ISA;

export const formatDrafts = (customerDrafts, otherDrafts) => {
  const legacyLisaAndPensionPotsDrafts = [
    ...getProductByWrapper(WRAPPER_TYPES.LISA, otherDrafts),
    ...getProductByWrapper(WRAPPER_TYPES.PENSION, otherDrafts)
  ];
  const customerDraftsWithWrapperIntent = customerDrafts.filter(customerDraft => hasValidWrapperIntent(customerDraft));
  const lisaDrafts = getProductByWrapper(WRAPPER_TYPES.LISA, customerDrafts);
  const uniqueCustomerDrafts = uniqBy(
    [...customerDraftsWithWrapperIntent, ...legacyLisaAndPensionPotsDrafts, ...lisaDrafts],
    'uuid'
  );
  const drafts = uniqueCustomerDrafts.map(customerDraft => {
    const { wrapper, wrapperIntent, investmentStyle, uuid, name } = customerDraft;
    const wrapperKey = wrapperIntent?.key || wrapper.key;

    return {
      draftId: uuid,
      name,
      investmentStyle,
      wrapperType: WRAPPER_TYPES[wrapperKey],
      productType: wrapperKey
    };
  });

  return drafts;
};
