import { ORGANISATIONS } from '@nm-utils-lib-web/organisations';

export const STEP_IMAGES = {
  PERSONAL_DETAILS: 'PERSONAL_DETAILS_IMAGE_URL',
  RISK_ASSESSMENT: 'RISK_ASSESSMENT_IMAGE_URL',
  AFFORDABILITY: 'AFFORDABILITY_IMAGE_URL',
  INVESTMENT_POT: 'INVESTMENT_POT_IMAGE_URL',
  FALLBACK: 'FALLBACK_IMAGE_URL'
};
export const IMAGE_MAP = {
  [ORGANISATIONS.NUTMEG_GBR_BIGBEAR]: {
    [STEP_IMAGES.PERSONAL_DETAILS]:
      'https://images.prismic.io/marketingwebsite/f9788adf-06b3-4dbe-bcde-d4a17ef139fc_Personal+details.png?auto=compress,format',
    [STEP_IMAGES.RISK_ASSESSMENT]:
      'https://images.prismic.io/marketingwebsite/20f2353e-ca60-44ea-8ab6-99c07f7c0b8a_Risk+assessment.png?auto=compress,format',
    [STEP_IMAGES.AFFORDABILITY]:
      'https://images.prismic.io/marketingwebsite/1240e233-4158-42c8-8d70-20ef264e4303_Affordability.png?auto=compress,format',
    [STEP_IMAGES.INVESTMENT_POT]:
      'https://images.prismic.io/marketingwebsite/401c6a94-1792-4d77-a7c9-fcd9a6b6f55c_Pot+creation.png?auto=compress,format',
    [STEP_IMAGES.FALLBACK]:
      'https://images.prismic.io/marketingwebsite/f9788adf-06b3-4dbe-bcde-d4a17ef139fc_Personal+details.png?auto=compress,format'
  },
  [ORGANISATIONS.JOHN_LEWIS_FINANCE_GBR]: {
    [STEP_IMAGES.PERSONAL_DETAILS]:
      'https://images.prismic.io/johnlewis/b3f44ac3-18ba-4e1e-9818-77228f7ea9d8_personal-details.png?auto=compress,format',
    [STEP_IMAGES.RISK_ASSESSMENT]:
      'https://images.prismic.io/johnlewis/6a7ddf67-521d-49f2-9fd8-58ff5981c9c9_risk.png?auto=compress,format',
    [STEP_IMAGES.AFFORDABILITY]:
      'https://johnlewis.cdn.prismic.io/johnlewis/fd476a76-bc19-406b-b7f3-efec3e7e20af_affordability-jlf.svg',
    [STEP_IMAGES.INVESTMENT_POT]:
      'https://images.prismic.io/johnlewis/7fc186f2-27fe-4baa-84e6-535656e27cfd_pot.png?auto=compress,format',
    [STEP_IMAGES.FALLBACK]:
      'https://images.prismic.io/johnlewis/b3f44ac3-18ba-4e1e-9818-77228f7ea9d8_personal-details.png?auto=compress,format'
  }
};
