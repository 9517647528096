import React, { useEffect } from 'react';
import { Banner } from '@nm-ui-shared-lib-web/banner';
import { Flags, useVariant, Variants } from '@nm-utils-lib-web/flags';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { Marketing } from '@nm-utils-lib-web/routes';
import { useBreakpoint, breakpointDirections, breakpoints } from '@nutkit/react-utils';

import { trackWealthServicesCTAVisible, EVENTS, CATEGORIES } from '../../../../tracking';

import { backgroundImages } from './constants';

const TRANSLATION_NAMESPACE = `signup.nonInvestor.content.wealthServicesCard`;
const variantsList = [Variants.VARIANT_B, Variants.VARIANT_C, Variants.VARIANT_D, Variants.VARIANT_E];
const variantVariations = (variantName, t) => ({
  title: t(`${TRANSLATION_NAMESPACE}.${variantName}.title`),
  description: t(`${TRANSLATION_NAMESPACE}.${variantName}.description`)
});

const WealthServicesCard = () => {
  const { t } = useTranslation();
  const { matchesCondition: isLargeUP } = useBreakpoint(breakpoints.LG, breakpointDirections.UP);
  const wealthServicesCTAVariant = useVariant(Flags.WEALTH_SERVICES_CTA_TEST);
  const isCardToBeDisplayed = wealthServicesCTAVariant?.enabled && variantsList.includes(wealthServicesCTAVariant.name);

  useEffect(() => {
    if (isCardToBeDisplayed) {
      trackWealthServicesCTAVisible();
    }
  }, [isCardToBeDisplayed]);

  if (isCardToBeDisplayed) {
    const props = {
      ...variantVariations(wealthServicesCTAVariant.name, t),
      backgroundImages,
      ctaText: t(`${TRANSLATION_NAMESPACE}.ctaText`),
      href: Marketing.getBookACallUrl(wealthServicesCTAVariant.payload?.value),
      eventPayload: {
        name: EVENTS.WEALTH_SERVICES_CTA_CLICKED,
        properties: {
          category: CATEGORIES.ONBOARDING
        }
      },
      isCompactLayout: isLargeUP
    };

    return <Banner {...props} />;
  }

  return null;
};

export { WealthServicesCard };
