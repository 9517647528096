import React, { Fragment } from 'react';
import { func, string, arrayOf, object, shape } from 'prop-types';
import Button, { buttonSizes, buttonCtaTypes } from '@nutkit/component-button';
import { Text, Heading, headingLevels, textStyles } from '@nutkit/component-text';
import Icon, { iconColors, iconSizes, IconBlock } from '@nutkit/component-icon';
import Link from '@nutkit/component-link';
import Panel, { PanelGhost } from '@nutkit/component-panel';
import { buttonGroupAlignments } from '@nutkit/component-button-group';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import { Trans } from '@nm-utils-lib-web/translations';
import { SupportArticles, Portfolio } from '@nm-utils-lib-web/routes';
import * as WRAPPER_TYPES from '@nm-pot/common/constants/wrapperTypes';

import CustomerSupportLink from '../../CustomerSupportLink';
import JISADependants from '../JISADependants/JISADependants';

const COMMON_TRANSLATION_NAMESPACE = 'signup.nonInvestor.content';
const TRANSLATION_NAMESPACE = `${COMMON_TRANSLATION_NAMESPACE}.openDraftsList`;
const WRAPPER_TYPE_TRANSLATION_NAMESPACE = `${COMMON_TRANSLATION_NAMESPACE}.wrapperTypeLabel`;
const IconLarge = () => <Icon size={iconSizes.MD} color={iconColors.ERROR} component={IconBlock} />;

const DraftsList = ({
  t,
  'data-qa': dataQa,
  eligibleDrafts,
  ineligibleDrafts,
  onContinuePot,
  openModal,
  eligibleProducts,
  error,
  onDeleteDrafts,
  jisaDependants
}) => {
  const userIsEligibleForJISA = eligibleProducts?.includes(WRAPPER_TYPES.JISA.toUpperCase());
  const hasEligibleDrafts = eligibleDrafts?.length > 0;
  const hasIneligibleDrafts = ineligibleDrafts?.length > 0;
  const hasJISADependants = Array.isArray(jisaDependants) && jisaDependants.length > 0;
  const shouldShowIneligiblePanel = hasIneligibleDrafts || (hasJISADependants && !userIsEligibleForJISA);
  const shouldShowEligiblePanel = hasEligibleDrafts || (hasJISADependants && userIsEligibleForJISA);

  const filterJISADrafts = draft => {
    return draft.wrapperType.toLowerCase() !== WRAPPER_TYPES.JISA;
  };
  const eligibleDraftsList =
    hasEligibleDrafts &&
    eligibleDrafts.filter(filterJISADrafts).map(draft => {
      const isPensionDraft = draft.wrapperType.toLowerCase() === WRAPPER_TYPES.PENSION;
      const actionsButtons = [
        <Button key="1" onClick={() => onContinuePot(draft)} data-qa={`${dataQa}-continue-set-up-button`}>
          {t(`${TRANSLATION_NAMESPACE}.continueButton`)}
        </Button>
      ];

      !isPensionDraft &&
        actionsButtons.unshift(
          <Button
            key="0"
            onClick={() => openModal(draft.draftId)}
            cta={buttonCtaTypes.SECONDARY}
            size={buttonSizes.SM}
            data-qa={`${dataQa}-delete-button`}
          >
            {t(`${TRANSLATION_NAMESPACE}.deleteButton`)}
          </Button>
        );

      return (
        <Fragment key={draft.draftId}>
          <PanelGhost data-qa={`${dataQa}-eligible-draft`} title={draft.name} actionButtons={actionsButtons}>
            <Text noStack textStyle={textStyles.TEXT_2}>
              {t(`${WRAPPER_TYPE_TRANSLATION_NAMESPACE}.${draft.wrapperType.toLowerCase()}`)}
            </Text>
          </PanelGhost>
          {isPensionDraft && eligibleDrafts.length === 1 && (
            <PanelGhost
              title={t(`${TRANSLATION_NAMESPACE}.createNewPotPanel.title`)}
              data-qa={`${dataQa}-create-new-pot-panel`}
              actionButtons={[
                <Link
                  button
                  buttonCta={buttonCtaTypes.SECONDARY}
                  href={`${global.NutmegConfig.DASHBOARD_HOST}${Portfolio.PRODUCT_SELECTION_PATH}`}
                >
                  {t(`${TRANSLATION_NAMESPACE}.createNewPotPanel.button`)}
                </Link>
              ]}
            >
              <Text noStack textStyle={textStyles.TEXT_2}>
                {t(`${TRANSLATION_NAMESPACE}.createNewPotPanel.description`)}
              </Text>
            </PanelGhost>
          )}
        </Fragment>
      );
    });
  const ineligibleDraftsList =
    hasIneligibleDrafts &&
    ineligibleDrafts.filter(filterJISADrafts).map(draft => (
      <PanelGhost
        key={draft.draftId}
        data-qa={`${dataQa}-ineligible-draft`}
        title={draft.name}
        alignButtons={buttonGroupAlignments.LEFT}
        actionButtons={[<IconLarge />]}
      >
        <Text noStack textStyle={textStyles.TEXT_2}>
          {t(`${WRAPPER_TYPE_TRANSLATION_NAMESPACE}.${draft.wrapperType.toLowerCase()}`)}
        </Text>
      </PanelGhost>
    ));

  return (
    <>
      {shouldShowEligiblePanel && (
        <Panel data-qa={`${dataQa}-eligible-panel`}>
          <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.eligibleTitle`)}</Heading>
          <Text>{t(`${TRANSLATION_NAMESPACE}.eligibleDescription`)}</Text>
          {error.draftId && (
            <Notification dismissable data-qa="request-failed-error" level={notificationLevels.ERROR}>
              <Trans
                i18nKey={`${TRANSLATION_NAMESPACE}.apiError`}
                values={{ potName: eligibleDrafts.find(draft => draft.draftId === error.draftId).name }}
                components={[<CustomerSupportLink />]}
              />
            </Notification>
          )}
          {jisaDependants && userIsEligibleForJISA && (
            <JISADependants
              dependants={jisaDependants}
              eligibleProducts={eligibleProducts}
              onDeleteDrafts={onDeleteDrafts}
            />
          )}
          {eligibleDraftsList}
        </Panel>
      )}
      {shouldShowIneligiblePanel && (
        <Panel data-qa={`${dataQa}-ineligible-panel`}>
          <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.ineligibleTitle`)}</Heading>
          <Text>{t(`${TRANSLATION_NAMESPACE}.ineligibleDescription`)}</Text>
          <Text>
            <Trans
              i18nKey={`${TRANSLATION_NAMESPACE}.ineligibleContactUs`}
              components={[
                <Link href={SupportArticles.KPMG_DELOITTE_CONTACT_US}>
                  {t(`${TRANSLATION_NAMESPACE}.ineligibleContactUs`)}
                </Link>
              ]}
            />
          </Text>
          {jisaDependants && (
            <JISADependants
              dependants={jisaDependants}
              eligibleProducts={eligibleProducts}
              onDeleteDrafts={onDeleteDrafts}
            />
          )}
          {ineligibleDraftsList}
        </Panel>
      )}
    </>
  );
};

DraftsList.propTypes = {
  'data-qa': string,
  t: func.isRequired,
  onContinuePot: func,
  eligibleProducts: arrayOf(string).isRequired,
  openModal: func.isRequired,
  eligibleDrafts: arrayOf(object),
  ineligibleDrafts: arrayOf(object),
  error: shape({ draftId: string }),
  onDeleteDrafts: func.isRequired,
  jisaDependants: arrayOf(object)
};

DraftsList.defaultProps = {
  'data-qa': 'drafts-list',
  onContinuePot: () => {},
  eligibleDrafts: undefined,
  ineligibleDrafts: undefined,
  error: {},
  jisaDependants: undefined
};

export default DraftsList;
