import React from 'react';
import { func, string } from 'prop-types';
import { Customer } from '@nm-utils-lib-web/routes';
import Panel from '@nutkit/component-panel';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import Button from '@nutkit/component-button';
import { Row, Col } from '@nutkit/component-grid';
import { Text, Heading, headingLevels, headingStyles } from '@nutkit/component-text';
import Image from '@nutkit/component-image';
import { useBreakpoint, breakpointDirections, breakpoints } from '@nutkit/react-utils';
import { useTranslation } from '@nm-utils-lib-web/translations';

import { STEP_IMAGES } from '../../../constants/images';
import { getImageUrl } from '../../../helpers/getImageUrl';

import styles from './AffordabilityPanel.module.scss';

const COMMON_TRANSLATION_NAMESPACE = 'signup.nonInvestor.content';
const TRANSLATION_NAMESPACE = 'signup.nonInvestor.content.affordabilityPanel';

const AffordabilityPanel = ({ 'data-qa': dataQa, onContinueButtonClick }) => {
  const { matchesCondition: isMobile } = useBreakpoint(breakpoints.MD, breakpointDirections.DOWN);
  const { t } = useTranslation();
  const IMAGE_URL = getImageUrl(STEP_IMAGES.AFFORDABILITY);

  const onClickContinue = () => {
    onContinueButtonClick && onContinueButtonClick();

    global.location.assign(Customer.AFFORDABILITY_PATH);
  };

  return (
    <Panel data-qa={`${dataQa}-main`}>
      <Row>
        <Col md="8" sm="12">
          <Heading level={headingLevels.TWO} headingStyle={headingStyles.TITLE_2}>
            {t(`${TRANSLATION_NAMESPACE}.title`)}
          </Heading>
          <Text>{t(`${TRANSLATION_NAMESPACE}.description`)}</Text>
          <ButtonGroup align={buttonGroupAlignments.LEFT} stackUp>
            <Button cta="primary" onClick={onClickContinue} data-qa={`${dataQa}-continue-button`}>
              {t(`${COMMON_TRANSLATION_NAMESPACE}.continueButton`)}
            </Button>
          </ButtonGroup>
        </Col>
        {!isMobile && (
          <Col md="4">
            <Image className={styles.nonInvestorNextStepImage} src={IMAGE_URL} alt="" aria-hidden />
          </Col>
        )}
      </Row>
    </Panel>
  );
};

AffordabilityPanel.propTypes = {
  'data-qa': string,
  onContinueButtonClick: func
};

AffordabilityPanel.defaultProps = {
  'data-qa': 'affordability-panel',
  onContinueButtonClick: undefined
};

export default AffordabilityPanel;
