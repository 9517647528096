import { useHistory } from 'react-router-dom';
import * as analytics from '@nm-utils-lib-web/analytics';
import { useCallback } from 'react';

const TRACKING_TIMEOUT = 500;

const useTrackedHistory = ({ trackingCategory }) => {
  const history = useHistory();
  const pushWithTracking = useCallback(
    (path, name, properties, redirectBeforeTracking = true) => {
      let trackingTimeout;

      const navigateToPath = () => {
        clearTimeout(trackingTimeout);
        history.push(path, properties);
      };

      trackingTimeout = setTimeout(navigateToPath, TRACKING_TIMEOUT);

      if (redirectBeforeTracking) {
        navigateToPath();
      }

      analytics.event({
        name,
        properties: { category: trackingCategory, ...properties },
        ...(!redirectBeforeTracking && { callback: navigateToPath })
      });

      return () => clearTimeout(trackingTimeout);
    },
    [history, trackingCategory]
  );

  return {
    ...history,
    pushWithTracking
  };
};

export default useTrackedHistory;
