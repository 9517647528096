import React from 'react';
import { bool, func, node } from 'prop-types';
import { Layout, LayoutNarrow } from '@nutkit/layouts';
import { OrganisationHeader } from '@nm-ui-shared-lib-web/organisation-header';
import ThemeProvider, { themeNames } from '@nutkit/theme';

import Footer from '../../components/Footer';

const OnboardingLayout = ({ children, isNarrow, NotificationComponent, noActionLogo }) => {
  const LayoutComponent = isNarrow ? LayoutNarrow : Layout;
  const showNotification = !!NotificationComponent;

  return (
    <>
      <ThemeProvider themeName={themeNames.DARK}>
        <OrganisationHeader
          translationNameSpace="signup.common.uiSharedOrganisationHeader"
          logoHref={noActionLogo ? null : window.NutmegConfig.MARKETING_HOST}
          showHelp
          noStack={showNotification}
        />
      </ThemeProvider>
      {showNotification && <NotificationComponent />}
      <LayoutComponent>{children}</LayoutComponent>
      <Footer />
    </>
  );
};

OnboardingLayout.propTypes = {
  children: node.isRequired,
  isNarrow: bool,
  NotificationComponent: func,
  noActionLogo: bool
};

OnboardingLayout.defaultProps = {
  isNarrow: false,
  NotificationComponent: undefined,
  noActionLogo: false
};

export default OnboardingLayout;
