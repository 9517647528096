import { ORGANISATIONS, isJohnLewisFinanceGBR } from '@nm-utils-lib-web/organisations';

import { IMAGE_MAP } from '../../constants/images';

export const getImageUrl = step => {
  if (isJohnLewisFinanceGBR()) {
    return IMAGE_MAP[ORGANISATIONS.JOHN_LEWIS_FINANCE_GBR][step];
  }

  return IMAGE_MAP[ORGANISATIONS.NUTMEG_GBR_BIGBEAR][step];
};
