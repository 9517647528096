import { STEPS } from '@nm-utils-lib-web/onboarding-journey-service';
import { Portfolio, Customer } from '@nm-utils-lib-web/routes';

import { NON_INVESTOR_DASHBOARD } from '../constants/RouterConstants';

const { DASHBOARD_HOST } = window.NutmegConfig;

/** @type {import('./convertJourneyStepToUrl.types').ConvertJourneyStepToUrl} */
export const convertJourneyStepToUrl = step => {
  switch (step) {
    case STEPS.FINANCIAL_DETAILS:
      return Customer.PERSONAL_DETAILS_PATH;
    case STEPS.PRODUCT_SELECT:
      return `${DASHBOARD_HOST}${Portfolio.PRODUCT_SELECTION_PATH}?onboarding=true`;
    case STEPS.MARKETING_CONSENT:
      return Customer.CUSTOMER_MARKETING_CONSENTS_PATH;
    case STEPS.NON_INVESTOR_DASHBOARD:
      return NON_INVESTOR_DASHBOARD;
    default:
      return false;
  }
};
