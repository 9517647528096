import React from 'react';
import { func, string } from 'prop-types';
import { Route } from 'react-router-dom';
import { ProtectedRoute as AuthenticationProtectedRoute } from '@nm-utils-lib-web/authentication';
import { MetaTitle } from '@nm-utils-lib-web/meta-title';
import { useTranslation } from '@nm-utils-lib-web/translations';

export const ProtectedRoute = ({ render, title, ...props }) => {
  const { t } = useTranslation();

  return (
    <Route
      {...props}
      render={() => (
        <>
          <MetaTitle title={t(title)} />
          <AuthenticationProtectedRoute render={render} />
        </>
      )}
    />
  );
};

ProtectedRoute.propTypes = {
  render: func.isRequired,
  title: string
};

ProtectedRoute.defaultProps = {
  title: 'global.common.organisation.appTitle'
};
