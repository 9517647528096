import handleError from '../../errorHandler';
import LegacyUserService from '../LegacyUserService';

/** @type {import('./getUser.types').GetUser} */
const getUser = async ({ customerUuid }) => {
  try {
    const { data } = await LegacyUserService.get(`/users/${customerUuid}`);

    return data;
  } catch (error) {
    handleError(error, 'Error getting user information');
  }
};

export default getUser;
