import React from 'react';
import { withErrorBoundary } from '@nm-utils-lib-web/error-boundary';

import NonInvestorDashboardPage from './NonInvestorDashboardPage';
import NonInvestorDashboardError from './NonInvestorDashboardError';

export default withErrorBoundary({
  contextualDescription: '[NI dashboard] Page failure',
  fallback: <NonInvestorDashboardError />
})(NonInvestorDashboardPage);
