import React from 'react';
import { string } from 'prop-types';
import { Heading, Text, headingLevels, textAligns, textWeights } from '@nutkit/component-text';
import Link, { buttonCtas } from '@nutkit/component-link';
import Panel from '@nutkit/component-panel';
import { useTranslation, Trans } from '@nm-utils-lib-web/translations';
import { SupportArticles } from '@nm-utils-lib-web/routes';
import Notification, { notificationLevels, notificationSizes } from '@nutkit/component-notification';
import Bold from '@nutkit/component-bold';
import ButtonGroup from '@nutkit/component-button-group';
import { LinkWithTracking } from '@nm-utils-lib-web/analytics';

import useSignupNotification from '../../hooks/useSignupNotification';
import OnboardingLayout from '../../layouts/OnboardingLayout';
import { CATEGORIES, EVENTS } from '../../tracking';

const TRANSLATION_NAMESPACE = 'signup.verifyEmail';

const VerifyEmailPage = ({ emailAddressToVerify: emailAddress }) => {
  const SignupNotification = useSignupNotification();
  const loginEventPayload = { name: EVENTS.VERIFY_EMAIL_LOG_IN };
  const loginEventCategory = CATEGORIES.ONBOARDING;
  const { t } = useTranslation();

  return (
    <OnboardingLayout isNarrow NotificationComponent={SignupNotification}>
      <Heading>{t(`${TRANSLATION_NAMESPACE}.heading`)}</Heading>
      <Panel>
        <Heading level={headingLevels.TWO}>{t(`${TRANSLATION_NAMESPACE}.subHeading`, { emailAddress })}</Heading>
        {emailAddress && (
          <>
            <Text>{t(`${TRANSLATION_NAMESPACE}.sentTheEmail`, { emailAddress })}</Text>
            <Text textAlign={textAligns.CENTER} textWeight={textWeights.BOLD}>
              {emailAddress}
            </Text>
          </>
        )}
        <Text>
          {t(`${TRANSLATION_NAMESPACE}.checkInbox`, { emailAddress })}
          <Bold weight={textWeights.BOLD}>{t(`${TRANSLATION_NAMESPACE}.expirePeriod`, { emailAddress })}</Bold>
        </Text>
        <Notification
          level={notificationLevels.INFORMATION}
          dismissable={false}
          size={notificationSizes.XS}
          data-qa="verify-email__message"
        >
          {t(`${TRANSLATION_NAMESPACE}.notification`)}
        </Notification>
        <Text>
          <Trans
            components={[
              <Link isExternal href={SupportArticles.CONTACT_FAQ_URL}>
                ` `
              </Link>
            ]}
            i18nKey={`${TRANSLATION_NAMESPACE}.notArrived`}
          />
        </Text>
      </Panel>
      <Panel>
        <Heading level={headingLevels.TWO}>
          {t(`${TRANSLATION_NAMESPACE}.emailBeenVerified.subHeading`, { emailAddress })}
        </Heading>
        <Text>{t(`${TRANSLATION_NAMESPACE}.emailBeenVerified.body`, { emailAddress })}</Text>
        <ButtonGroup align={textAligns.RIGHT}>
          <LinkWithTracking
            button
            buttonCta={buttonCtas.PRIMARY}
            href={window.NutmegConfig.LOGIN_HOST}
            eventPayload={loginEventPayload}
            category={loginEventCategory}
          >
            {t(`${TRANSLATION_NAMESPACE}.emailBeenVerified.cta`)}
          </LinkWithTracking>
        </ButtonGroup>
      </Panel>

      {/* <VerifyEmail
        loginEventPayload={verifyEmailLoginEvent}
        loginEventCategory={verifyEmailLoginCategory}
        emailAddress={emailAddressToVerify}
      /> */}
    </OnboardingLayout>
  );
};

VerifyEmailPage.propTypes = {
  emailAddressToVerify: string
};

VerifyEmailPage.defaultProps = {
  emailAddressToVerify: ''
};

export default VerifyEmailPage;
