import React from 'react';
import { arrayOf, object, string, func } from 'prop-types';
import JISADraftSummary from '@nm-pot/create-jisa/components/JISADraftSummary';
import * as WRAPPER_TYPES from '@nm-pot/common/constants/wrapperTypes';
import { PanelGhost } from '@nutkit/component-panel';
import { Text, textStyles } from '@nutkit/component-text';
import Button, { buttonCtaTypes } from '@nutkit/component-button';
import { buttonGroupAlignments } from '@nutkit/component-button-group';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { useGetAuthenticationMetadata } from '@nm-utils-lib-web/authentication';
import JisaProfileModal from '@nm-customer/jisa-profile/components/JisaProfileModal';

const TRANSLATION_NAMESPACE = 'signup.nonInvestor.jisaDependants';

const JISADependants = ({ dependants, eligibleProducts, onDeleteDrafts }) => {
  const userIsEligibleForJISA = eligibleProducts?.includes(WRAPPER_TYPES.JISA.toUpperCase());
  const { t } = useTranslation();
  const { customerUuid } = useGetAuthenticationMetadata();
  const [childCustomerUuidToRemove, setChildCustomerUuidToRemove] = React.useState('');
  const [removedChildIds, setRemovedChildIds] = React.useState([]);

  const onDeleteJisa = childId => {
    setRemovedChildIds(removedChildren => [...removedChildren, childId]);

    setChildCustomerUuidToRemove('');
    onDeleteDrafts();
  };
  const removedFilter = ({ customerId }) => !removedChildIds.includes(customerId);

  if (!userIsEligibleForJISA) {
    return (
      <>
        {dependants.filter(removedFilter).map(({ customerId, firstName }) => (
          <PanelGhost
            key={customerId}
            data-qa="jisa-dependant-ineligible"
            title={t(`${TRANSLATION_NAMESPACE}.ineligible.title`, { firstName })}
            alignButtons={buttonGroupAlignments.LEFT}
            actionButtons={[
              <Button
                cta={buttonCtaTypes.SECONDARY}
                aria-label={t(`${TRANSLATION_NAMESPACE}.delete.ariaLabel`, {
                  name: firstName,
                  interpolation: {
                    escapeValue: false
                  }
                })}
                key="delete"
                onClick={() => setChildCustomerUuidToRemove(customerId)}
              >
                {t(`${TRANSLATION_NAMESPACE}.delete.label`)}
              </Button>
            ]}
          >
            <Text noStack textStyle={textStyles.TEXT_2}>
              {t(`${TRANSLATION_NAMESPACE}.ineligible.description`)}
            </Text>
          </PanelGhost>
        ))}
        <JisaProfileModal
          isOpen={!!childCustomerUuidToRemove}
          setIsOpen={setChildCustomerUuidToRemove}
          onDelete={() => onDeleteJisa(childCustomerUuidToRemove)}
          jisaAccountId={childCustomerUuidToRemove}
          customerUuid={customerUuid}
          data-qa="jisa-profile__delete-app-modal"
        />
      </>
    );
  }

  return dependants
    .filter(removedFilter)
    .map(({ firstName, customerId: childCustomerUuid }) => (
      <JISADraftSummary
        customerUuid={customerUuid}
        childCustomerUuid={childCustomerUuid}
        childFirstName={firstName}
        key={childCustomerUuid}
        onDeleteJisaDraft={onDeleteJisa}
      />
    ));
};

JISADependants.propTypes = {
  eligibleProducts: arrayOf(string).isRequired,
  dependants: arrayOf(object).isRequired,
  onDeleteDrafts: func.isRequired
};

export default JISADependants;
