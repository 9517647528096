import get from 'lodash/get';
import { usePrismic } from '@nm-marketing/react-utils';
import { usePrismicNotificationBanner } from '@nm-ui-shared-lib-web/login-banner';

import { getBannerSignup } from '../services/PrismicService';

/** @type {import('./useSignupNotification.types').useSignupNotification} */
const useSignupNotification = (noStack = false) => {
  const { response } = usePrismic(getBannerSignup);
  const Notification = usePrismicNotificationBanner({ prismicData: get(response, 'data'), noStack });

  return Notification;
};

export default useSignupNotification;
