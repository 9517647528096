import { NonAuthenticatedUserService } from './LegacyUserService';
import handleError from './errorHandler';

const setJISAPassword = async ({ token, password }) => {
  try {
    const { data } = await NonAuthenticatedUserService.post('/v1/users/set_jisa_password', { token, password });

    return data;
  } catch (error) {
    handleError(error, 'Error setting password');
  }
};

export default setJISAPassword;
