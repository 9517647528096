import React from 'react';
import { useTranslation, Trans } from '@nm-utils-lib-web/translations';
import { Login, SupportArticles } from '@nm-utils-lib-web/routes';
import Panel from '@nutkit/component-panel';
import { Text, Heading, headingLevels, textStyles, textAligns } from '@nutkit/component-text';
import ButtonGroup from '@nutkit/component-button-group';
import { buttonCtaTypes } from '@nutkit/component-button';
import Link from '@nutkit/component-link';

import OnboardingLayout from '../../layouts/OnboardingLayout';

const TRANSLATION_NAMESPACE = 'signup.verificationFailed';

export const VerificationFailedPage = () => {
  const { t } = useTranslation();

  return (
    <OnboardingLayout>
      <Heading textStyle={textStyles.TITLE_2}>{t(`${TRANSLATION_NAMESPACE}.heading`)}</Heading>
      <Panel>
        <Heading level={headingLevels.TWO} textStyle={textStyles.TITLE_3}>
          {t(`${TRANSLATION_NAMESPACE}.panel.heading`)}
        </Heading>
        <Text>
          <Trans
            i18nKey={`${TRANSLATION_NAMESPACE}.panel.description`}
            components={[
              <Link data-qa="support-link" isExternal href={SupportArticles.CONTACT_FAQ_URL}>
                <></>
              </Link>
            ]}
          />
        </Text>
        <ButtonGroup align={textAligns.RIGHT}>
          <Link button buttonCta={buttonCtaTypes.PRIMARY} href={Login.LOGOUT_PATH} data-qa="logout-button">
            {t(`${TRANSLATION_NAMESPACE}.panel.logoutButton`)}
          </Link>
        </ButtonGroup>
      </Panel>
    </OnboardingLayout>
  );
};
