import React from 'react';
import Link from '@nutkit/component-link';
import { useTranslation } from '@nm-utils-lib-web/translations';

const CustomerSupportLink = ({ ...props }) => {
  const { t } = useTranslation();
  const supportEmail = t('global.common.organisation.contact.email');

  return (
    <Link href={`mailto:${supportEmail}`} {...props}>
      {supportEmail}
    </Link>
  );
};

export default CustomerSupportLink;
