// eslint-disable-next-line max-len
import { organisationHeaderTranslations as headerLanguage } from '@nm-ui-shared-lib-web/organisation-header/languages/johnlewisfinance-gbr';
// eslint-disable-next-line max-len
import { organisationFooterTranslations as footerLanguage } from '@nm-ui-shared-lib-web/organisation-footer/languages/johnlewisfinance-gbr';
import noMatchLanguage from '@nm-utils-lib-web/no-match/languages/johnlewisfinance-gbr.json';

import registerPage from './registerPage.json';

export default {
  signup: {
    common: {
      ...headerLanguage,
      ...footerLanguage,
      ...noMatchLanguage
    },
    registerPage
  }
};
