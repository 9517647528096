import React from 'react';
import { string } from 'prop-types';
import { usePrismic } from '@nm-marketing/react-utils';
import NotificationBanner from '@nm-marketing/component-notification-banner';
import { combineFetchLinks, ContentTypes } from '@nm-marketing/prismic-utils';
import { createNotificationBannerModel, FETCH_LINKS_LINK } from '@nm-marketing/prismic-models';

const FETCH_LINKS = combineFetchLinks([FETCH_LINKS_LINK]);

const fetchBanner = prismicService => {
  return prismicService.getSingle(ContentTypes.BANNER_NON_INVESTOR, {
    fetchLinks: FETCH_LINKS
  });
};

const NonInvestorDashboardBanner = ({ className }) => {
  const bannerData = usePrismic(fetchBanner);
  const bannerBody = bannerData?.response?.data?.body?.[0];
  const prismicBannerProps = !bannerData.isLoading && createNotificationBannerModel(bannerBody);

  return prismicBannerProps && <NotificationBanner className={className} {...prismicBannerProps} />;
};

NonInvestorDashboardBanner.propTypes = {
  className: string.isRequired
};

export default NonInvestorDashboardBanner;
