import React from 'react';
import { useTranslation, Trans } from '@nm-utils-lib-web/translations';
import { Heading, headingLevels } from '@nutkit/component-text';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import Link from '@nutkit/component-link';
import { SupportArticles } from '@nm-utils-lib-web/routes';

import OnboardingLayout from '../../../layouts/OnboardingLayout';

const TRANSLATION_NAMESPACE = 'signup.nonInvestor.errorScreen';

const NonInvestorDashboardError = () => {
  const { t } = useTranslation();

  return (
    <OnboardingLayout>
      <Heading data-qa="ni-page-error__title">{t(`${TRANSLATION_NAMESPACE}.title`)}</Heading>
      <Heading level={headingLevels.TWO} data-qa="ni-page-error__subtitle">
        {t(`${TRANSLATION_NAMESPACE}.subtitle`)}
      </Heading>
      <Notification dismissable={false} level={notificationLevels.ERROR}>
        <Trans
          i18nKey={`${TRANSLATION_NAMESPACE}.notification`}
          components={[
            <Link isExternal href={SupportArticles.CONTACT_FAQ_URL}>
              <></>
            </Link>
          ]}
        />
      </Notification>
    </OnboardingLayout>
  );
};

export default NonInvestorDashboardError;
