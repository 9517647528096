import { STATES } from '@nm-utils-lib-web/onboarding-journey-service';

export const shouldShowPotPanel = status =>
  status.has.includes(STATES.HAS_IDENTIFICATION_DETAILS) &&
  status.has.includes(STATES.HAS_JURISDICTION) &&
  status.has.includes(STATES.HAS_IDV) &&
  status.has.includes(STATES.HAS_FINANCIAL_DETAILS) &&
  status.has.includes(STATES.HAS_EMPLOYMENT) &&
  status.has.includes(STATES.HAS_TERMS_AND_CONDITIONS) &&
  status.has.includes(STATES.HAS_RISK_PROFILE) &&
  status.needs.includes(STATES.NEEDS_CONFIRMED_POT);
