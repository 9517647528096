import React from 'react';
import { shape, string, array } from 'prop-types';
import { STEPS } from '@nm-utils-lib-web/onboarding-journey-service';

import { trackNonInvestorContinue } from '../../../tracking';
import FallbackPanel from '../FallbackPanel';
import PersonalDetailsPanel from '../PersonalDetailsPanel';
import InvestmentPotPanel from '../InvestmentPotPanel';
import RiskPanel from '../RiskPanel';
import AffordabilityPanel from '../AffordabilityPanel';
import { PERSONAL_AND_FINANCIAL_DETAILS_STEPS } from '../../../constants/NonInvestorConstants';

/** @type {MainPanel} */
const MainPanel = ({ nextStep, status }) => {
  const onContinueButtonClick = (props = {}) => {
    trackNonInvestorContinue({ ...props, nextStep });
  };

  if (PERSONAL_AND_FINANCIAL_DETAILS_STEPS.includes(nextStep)) {
    return <PersonalDetailsPanel onContinueButtonClick={onContinueButtonClick} nextStep={nextStep} />;
  }
  if (nextStep === STEPS.RISK_ASSESSMENT) {
    return <RiskPanel onContinueButtonClick={onContinueButtonClick} />;
  }
  if (nextStep === STEPS.CREATE_POT) {
    return <InvestmentPotPanel onContinueButtonClick={onContinueButtonClick} />;
  }
  if (nextStep === STEPS.AFFORDABILITY) {
    return <AffordabilityPanel onContinueButtonClick={onContinueButtonClick} />;
  }

  return <FallbackPanel status={status} onContinueButtonClick={onContinueButtonClick} />;
};

MainPanel.propTypes = {
  nextStep: string,
  status: shape({ has: array, needs: array }).isRequired
};

MainPanel.defaultProps = {
  nextStep: undefined
};

export default MainPanel;
