import React, { useRef } from 'react';
import { func, string } from 'prop-types';
import Panel from '@nutkit/component-panel';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import Button from '@nutkit/component-button';
import Loading from '@nutkit/component-loading';
import { Row, Col } from '@nutkit/component-grid';
import Image from '@nutkit/component-image';
import { Text, Heading, headingLevels, headingStyles } from '@nutkit/component-text';
import { useBreakpoint, breakpointDirections, breakpoints } from '@nutkit/react-utils';
import { Portfolio, Pension, Customer } from '@nm-utils-lib-web/routes';
import * as WRAPPER_TYPES from '@nm-pot/common/constants/wrapperTypes';
import deleteDraftPotService from '@nm-pot/common/services/deleteDraftPotService';
import { useGetAuthenticationMetadata } from '@nm-utils-lib-web/authentication';
import { JOURNEY_TYPES } from '@nm-pot/common/constants';
import { getDraftContinuePath } from '@nm-pot/overview/helpers';
import { useFlag, Flags } from '@nm-utils-lib-web/flags';
import qs from 'qs';
import { useHandleError } from '@nm-utils-lib-web/error-boundary';
import { attachBackUrlTo } from '@nm-utils-lib-web/back-url';
import useGetEligibleProducts from '@nm-portfolio-lib-web/common/hooks/useGetEligibleProducts';
import { useTranslation } from '@nm-utils-lib-web/translations';

import { formatDrafts } from '../../../helpers/formatDrafts';
import DraftsList from '../DraftsList';
import { formatEligibilityData } from '../../../helpers/eligibleDraftsHelpers';
import useGetAllPots from '../../../hooks/useGetAllPots';
import { backToNonInvestorQueryParam, onboardingTrueQueryParam } from '../../../constants/NonInvestorConstants';
import DeleteDraftPotModal from '../DeleteDraftPotModal';
import { STEP_IMAGES } from '../../../constants/images';
import { getImageUrl } from '../../../helpers/getImageUrl';

import styles from './InvestmentPotPanel.module.scss';

const COMMON_TRANSLATION_NAMESPACE = 'signup.nonInvestor.content';
const TRANSLATION_NAMESPACE = `${COMMON_TRANSLATION_NAMESPACE}.investmentPotPanel`;
const WRAPPER_TYPE_TRANSLATION_NAMESPACE = `${COMMON_TRANSLATION_NAMESPACE}.wrapperTypeLabel`;

const InvestmentPotPanel = ({ 'data-qa': dataQa, onContinueButtonClick }) => {
  const { t } = useTranslation();
  const { customerUuid } = useGetAuthenticationMetadata();
  const { matchesCondition: isMobile } = useBreakpoint(breakpoints.MD, breakpointDirections.DOWN);
  const { triggerError } = useHandleError();
  const timer = useRef(null); // we can save timer in useRef and pass it to child
  const timeoutDuration = 2500;
  const [isWaitingToRefresh, setIsWaitingToRefresh] = React.useState(false);
  const isLISATransferEnabled = useFlag(Flags.LISA_TRANSFERS);
  const { customerDrafts, potData, jisaDependants, isLoadingAllPots, getDrafts, getAllPotData } = useGetAllPots({
    customerUuid,
    triggerError
  });
  const drafts = formatDrafts(customerDrafts, potData);
  const {
    data: eligibilityData,
    isLoading: isLoadingEligibilityData,
    getEligibleProducts
  } = useGetEligibleProducts({
    customerUuid,
    isLISATransferEnabled
  });
  const {
    firstEligibleDraft,
    eligibleProducts,
    eligibleDrafts,
    ineligibleDrafts,
    hasEligibleDraftPots,
    hasIneligibleDraftPots
  } = formatEligibilityData(eligibilityData, drafts, jisaDependants);
  const isLoading = isLoadingAllPots || isWaitingToRefresh || isLoadingEligibilityData;
  const [deleteDraftPotError, setDeleteDraftPotError] = React.useState({});
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [modalState, setModalState] = React.useState(
    /** @type {import('./InvestmentPotPanel.types').ModalState} */ ({})
  );
  const IMAGE_URL = getImageUrl(STEP_IMAGES.INVESTMENT_POT);

  /* istanbul ignore next */
  React.useEffect(() => () => timer.current && clearTimeout(timer.current), []);

  const onDeleteDrafts = () => {
    setIsWaitingToRefresh(true);
    timer.current = setTimeout(() => {
      getAllPotData();
      getEligibleProducts();
      setIsWaitingToRefresh(false);
    }, timeoutDuration);
  };

  const onDeleteError = draftId => {
    setIsDeleting(false);
    setDeleteDraftPotError({ draftId });
  };

  const onClickStartNew = () => {
    onContinueButtonClick && onContinueButtonClick({ potUuid: 'new' });
    window.location.assign(
      `${global.NutmegConfig.DASHBOARD_HOST}${Portfolio.PRODUCT_SELECTION_PATH}${qs.stringify(
        onboardingTrueQueryParam,
        { addQueryPrefix: true }
      )}`
    );
  };

  const onClickContinuePot = draft => {
    const navigateToRedirectUrl = redirectUrl => {
      redirectUrl && window.location.assign(redirectUrl);
    };
    const queryParams = qs.stringify(
      {
        ...backToNonInvestorQueryParam,
        ...onboardingTrueQueryParam
      },
      { addQueryPrefix: true }
    );

    onContinueButtonClick && onContinueButtonClick({ potUuid: draft.draftId, wrapperType: draft.wrapperType });

    if (draft.wrapperType === WRAPPER_TYPES.PENSION) {
      const redirectUrl = `${global.NutmegConfig.DASHBOARD_HOST}${Pension.getPensionPotJourneyPath({
        potUuid: draft.draftId,
        journeyType: JOURNEY_TYPES.CREATE_POT
      })}${queryParams}`;

      return navigateToRedirectUrl(redirectUrl);
    }
    if (draft.draftId) {
      const redirectUrl = getDraftContinuePath({
        ...draft,
        wrapperType: draft.wrapperType === WRAPPER_TYPES.GIA ? WRAPPER_TYPES.SISA : draft.wrapperType,
        draftUuid: draft.draftId,
        queryParams: onboardingTrueQueryParam
      });

      return navigateToRedirectUrl(redirectUrl);
    }
    /* istanbul ignore next */
    if (draft.childUuid) {
      const redirectUrl = attachBackUrlTo(
        `${global.NutmegConfig.DASHBOARD_HOST}${Customer.getJisaProfilePath({
          childCustomerId: draft.customerId
        })}${queryParams}`
      );

      return navigateToRedirectUrl(redirectUrl);
    }
  };

  const onOpenModal = draftId => {
    setModalState({ isOpen: !modalState.isOpen, draftId });
  };

  const onDeleteConfirm = async () => {
    try {
      setIsDeleting(true);
      await deleteDraftPotService({ potUuid: modalState.draftId });
      await getDrafts();
      setIsDeleting(false);
    } catch (e) {
      onDeleteError(modalState.draftId);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <DeleteDraftPotModal
        onConfirm={onDeleteConfirm}
        isOpen={modalState.isOpen}
        onToggle={() => setModalState(prevModalState => ({ ...prevModalState, isOpen: !prevModalState.isOpen }))}
      />
      <Panel data-qa={`${dataQa}-main`}>
        <Row className={styles.nonInvestorRowAlignCenter}>
          <Col md="8" sm="12">
            {hasEligibleDraftPots ? (
              <>
                <Heading
                  data-qa={`${dataQa}-continue-title`}
                  level={headingLevels.TWO}
                  headingStyle={headingStyles.TITLE_2}
                >
                  {t(`${TRANSLATION_NAMESPACE}.continueTitle`, {
                    productType: t(
                      `${WRAPPER_TYPE_TRANSLATION_NAMESPACE}.${firstEligibleDraft.productType.toLowerCase()}`
                    ),
                    interpolation: { escapeValue: false }
                  })}
                </Heading>
                <Text>{t(`${TRANSLATION_NAMESPACE}.continueDescription`)}</Text>
                <ButtonGroup align={buttonGroupAlignments.LEFT} stackUp stack>
                  <Button onClick={() => onClickContinuePot(firstEligibleDraft)} data-qa={`${dataQa}-continue-button`}>
                    {t(`${TRANSLATION_NAMESPACE}.continueButton`)}
                  </Button>
                </ButtonGroup>
              </>
            ) : (
              <>
                <Heading
                  data-qa={`${dataQa}-start-pot-title`}
                  level={headingLevels.TWO}
                  headingStyle={headingStyles.TITLE_2}
                >
                  {t(`${TRANSLATION_NAMESPACE}.title`)}
                </Heading>
                <Text>{t(`${TRANSLATION_NAMESPACE}.description`)}</Text>
                <ButtonGroup align={buttonGroupAlignments.LEFT} stackUp stack>
                  <Button onClick={onClickStartNew} data-qa={`${dataQa}-start-button`}>
                    {t(`${TRANSLATION_NAMESPACE}.startButton`)}
                  </Button>
                </ButtonGroup>
              </>
            )}
          </Col>
          {!isMobile && (
            <Col md="4">
              <Image
                className={styles.nonInvestorNextStepImage}
                src={IMAGE_URL}
                alt=""
                aria-hidden
                data-qa={`${dataQa}-main-image`}
              />
            </Col>
          )}
        </Row>
      </Panel>
      {(hasEligibleDraftPots || hasIneligibleDraftPots) && !isDeleting && (
        <DraftsList
          data-qa={`${dataQa}-draft-list`}
          eligibleDrafts={eligibleDrafts}
          onContinuePot={onClickContinuePot}
          openModal={onOpenModal}
          error={deleteDraftPotError}
          onDeleteDrafts={onDeleteDrafts}
          ineligibleDrafts={ineligibleDrafts}
          eligibleProducts={eligibleProducts}
          jisaDependants={jisaDependants}
          hasEligibleDrafts={hasEligibleDraftPots}
          hasIneligibleDrafts={hasIneligibleDraftPots}
        />
      )}
      {(hasEligibleDraftPots || hasIneligibleDraftPots) && isDeleting && <Loading />}
    </>
  );
};

InvestmentPotPanel.propTypes = {
  'data-qa': string,
  onContinueButtonClick: func
};

InvestmentPotPanel.defaultProps = {
  'data-qa': 'investment-pot-panel',
  onContinueButtonClick: undefined
};

export default InvestmentPotPanel;
