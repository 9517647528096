import React from 'react';
import { func, string } from 'prop-types';
import { StatusCodes } from 'http-status-codes';
import Panel from '@nutkit/component-panel';
import Image from '@nutkit/component-image';
import { Text, Heading, headingLevels, headingStyles } from '@nutkit/component-text';
import Button from '@nutkit/component-button';
import ButtonGroup, { buttonGroupAlignments } from '@nutkit/component-button-group';
import { Row, Col } from '@nutkit/component-grid';
import { useBreakpoint, breakpointDirections, breakpoints } from '@nutkit/react-utils';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import { useGetKycCheckAll } from '@nm-customer/common/hooks/useGetKycCheckAll';
import { useHandleError } from '@nm-utils-lib-web/error-boundary';
import { useGetAuthenticationMetadata } from '@nm-utils-lib-web/authentication';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { generateRedirectMap } from '@nm-utils-lib-web/onboarding-journey-service/helpers/generateRedirectMap';

import { STEP_IMAGES } from '../../../constants/images';
import { getImageUrl } from '../../../helpers/getImageUrl';

import styles from './PersonalDetailsPanel.module.scss';

const COMMON_TRANSLATION_NAMESPACE = 'signup.nonInvestor.content';
const TRANSLATION_NAMESPACE = `${COMMON_TRANSLATION_NAMESPACE}.personalDetailsPanel`;
const PROCESSING_TRANSLATION_NAMESPACE = 'signup.processingInfo';

const PersonalDetailsPanel = ({ 'data-qa': dataQa, onContinueButtonClick, nextStep }) => {
  const { t } = useTranslation();
  const { matchesCondition: isMobile } = useBreakpoint(breakpoints.MD, breakpointDirections.DOWN);
  const IMAGE_URL = getImageUrl(STEP_IMAGES.PERSONAL_DETAILS);

  const onClickContinue = () => {
    onContinueButtonClick && onContinueButtonClick();

    const nextPath = generateRedirectMap({
      isOnboarding: true
    })[nextStep];

    global.location.assign(nextPath);
  };
  const { triggerError } = useHandleError();
  const { customerUuid } = useGetAuthenticationMetadata();
  const { data } = useGetKycCheckAll({
    customerUuid,
    onError: error => {
      if (error?.response?.status !== StatusCodes.NOT_FOUND) {
        return triggerError(error);
      }
    }
  });
  const hasComplianceFailed =
    data?.overallResult && data?.overallResult !== 'PASS' && data?.overallResult !== 'UNKNOWN';

  return (
    <Panel data-qa={`${dataQa}-main`}>
      <Row className={styles.nonInvestorRowAlignCenter}>
        <Col md="8" sm="12">
          <Heading level={headingLevels.TWO} headingStyle={headingStyles.TITLE_2}>
            {t(`${TRANSLATION_NAMESPACE}.title`)}
          </Heading>
          {hasComplianceFailed ? (
            <Notification
              level={notificationLevels.INFORMATION}
              dismissable={false}
              data-qa={`${dataQa}-asking-notification`}
            >
              {t(`${PROCESSING_TRANSLATION_NAMESPACE}.panel.text.topText`)}
            </Notification>
          ) : (
            <Text>{t(`${TRANSLATION_NAMESPACE}.description`)}</Text>
          )}
          <ButtonGroup align={buttonGroupAlignments.LEFT} stackUp>
            <Button onClick={onClickContinue} data-qa={`${dataQa}-continue-button`} disabled={!!hasComplianceFailed}>
              {t(`${COMMON_TRANSLATION_NAMESPACE}.continueButton`)}
            </Button>
          </ButtonGroup>
        </Col>
        {!isMobile && (
          <Col md="4">
            <Image className={styles.nonInvestorNextStepImage} src={IMAGE_URL} alt="" aria-hidden />
          </Col>
        )}
      </Row>
    </Panel>
  );
};

PersonalDetailsPanel.propTypes = {
  'data-qa': string,
  onContinueButtonClick: func,
  nextStep: string.isRequired
};

PersonalDetailsPanel.defaultProps = {
  'data-qa': 'personal-details-panel',
  onContinueButtonClick: undefined
};

export default PersonalDetailsPanel;
