import React from 'react';
import { func, string, shape, array } from 'prop-types';
import Panel from '@nutkit/component-panel';
import Image from '@nutkit/component-image';
import { Text, Heading, headingLevels, headingStyles } from '@nutkit/component-text';
import Button from '@nutkit/component-button';
import ButtonGroup from '@nutkit/component-button-group';
import { Row, Col } from '@nutkit/component-grid';
import { useBreakpoint, breakpointDirections, breakpoints } from '@nutkit/react-utils';
import { STEPS } from '@nm-utils-lib-web/onboarding-journey-service';
import { useTranslation } from '@nm-utils-lib-web/translations';

import { convertJourneyStepToUrl } from '../../../utils/convertJourneyStepToUrl';
import { shouldShowPotPanel } from '../../../helpers/statusHelpers/statusHelpers';
import InvestmentPotPanel from '../InvestmentPotPanel';
import { STEP_IMAGES } from '../../../constants/images';
import { getImageUrl } from '../../../helpers/getImageUrl';

import styles from './FallbackPanel.module.scss';

const TRANSLATION_NAMESPACE = 'signup.nonInvestor.content.fallbackPanel';

const FallbackPanel = ({ 'data-qa': dataQa, onContinueButtonClick, status }) => {
  const { t } = useTranslation();
  const { matchesCondition: isMobile } = useBreakpoint(breakpoints.MD, breakpointDirections.DOWN);
  const IMAGE_URL = getImageUrl(STEP_IMAGES.FALLBACK);

  const onClickContinue = () => {
    onContinueButtonClick && onContinueButtonClick();
    const redirectUrl = convertJourneyStepToUrl(STEPS.MARKETING_CONSENT);

    if (redirectUrl) {
      global.location.assign(redirectUrl);
    }
  };

  return shouldShowPotPanel(status) ? (
    <InvestmentPotPanel onContinueButtonClick={onContinueButtonClick} />
  ) : (
    <Panel data-qa={dataQa}>
      <Row className={styles.nonInvestorRowAlignCenter}>
        <Col md="8" sm="12">
          <Heading level={headingLevels.TWO} headingStyle={headingStyles.TITLE_2}>
            {t(`${TRANSLATION_NAMESPACE}.title`)}
          </Heading>
          <Text>{t(`${TRANSLATION_NAMESPACE}.description`)}</Text>
          <ButtonGroup stackUp>
            <Button onClick={onClickContinue} data-qa={`${dataQa}-continue-button`}>
              {t(`${TRANSLATION_NAMESPACE}.continueButton`)}
            </Button>
          </ButtonGroup>
        </Col>
        {!isMobile && (
          <Col md="4">
            <Image className={styles.nonInvestorNextStepImage} src={IMAGE_URL} alt="" aria-hidden />
          </Col>
        )}
      </Row>
    </Panel>
  );
};

FallbackPanel.propTypes = {
  'data-qa': string,
  status: shape({ has: array, needs: array }).isRequired,
  onContinueButtonClick: func
};

FallbackPanel.defaultProps = {
  'data-qa': 'fallback-panel',
  onContinueButtonClick: undefined
};

export default FallbackPanel;
