import React from 'react';
import { node } from 'prop-types';
import { Text, Heading, headingLevels, textStyles } from '@nutkit/component-text';
import { LayoutWithSideMenuAndHeader, LayoutWithSidebar } from '@nutkit/layouts';
import Section, { stackSpacing, sectionTagNames } from '@nutkit/component-section';
import { OrganisationHeader } from '@nm-ui-shared-lib-web/organisation-header';
import { OrganisationFooter } from '@nm-ui-shared-lib-web/organisation-footer';
import ThemeProvider, { themeNames } from '@nutkit/theme';
import { useTranslation } from '@nm-utils-lib-web/translations';

import NonInvestorBanner from '../NonInvestorDashboardBanner';

const TRANSLATION_NAMESPACE = 'signup.nonInvestor.content';
const wrapperClass = 'non-investor-page';
const layoutWrapperId = `${wrapperClass}-layout`;

export const Footer = props => (
  <ThemeProvider themeName={themeNames.DARK}>
    <OrganisationFooter
      translationNameSpace="signup.common.footer"
      showAppDownload={false}
      containerFluid="xl"
      noBackground
      {...props}
    />
  </ThemeProvider>
);

const NonInvestorDashboardLayout = ({ sideMenu, main, sidebar }) => {
  const { t } = useTranslation();

  return (
    <LayoutWithSideMenuAndHeader
      className={wrapperClass}
      sideMenu={sideMenu}
      header={({ onToggle, noStack }) => (
        <ThemeProvider themeName={themeNames.DARK}>
          <OrganisationHeader
            translationNameSpace="signup.common.uiSharedOrganisationHeader"
            logoHref="/"
            showMenu
            onHelpButtonClick={onToggle}
            topFixed
            noStack={noStack}
          />
        </ThemeProvider>
      )}
      banner={<NonInvestorBanner className="non-investor-dashboard-banner" />}
      footer={<Footer />}
    >
      <Section tagName={sectionTagNames.DIV} id={layoutWrapperId}>
        <LayoutWithSidebar
          header={
            <Section tagName={sectionTagNames.DIV} stackSpacing={stackSpacing.XL}>
              <Text noStack>{t(`${TRANSLATION_NAMESPACE}.welcome`)}</Text>
              <Heading textStyle={textStyles.TITLE_1} level={headingLevels.ONE}>
                {t(`${TRANSLATION_NAMESPACE}.mainHeading`)}
              </Heading>
            </Section>
          }
          main={main}
          sidebar={sidebar}
        />
      </Section>
    </LayoutWithSideMenuAndHeader>
  );
};

NonInvestorDashboardLayout.propTypes = {
  sideMenu: node.isRequired,
  main: node.isRequired,
  sidebar: node.isRequired
};

export default NonInvestorDashboardLayout;
