import useSWR from 'swr';
import { getSWRIsLoading } from '@nm-utils-lib-web/network/swr';

import getUser from '../services/LegacyUserService/methods/getUser';

/** @type {import('./useGetUser.types').UseGetUser} */
const useGetUser = ({ customerUuid, shouldMakeRequest = true, onError = () => {} }) => {
  const cacheKey = `getUser/${customerUuid}`;
  const { data, error } = useSWR(shouldMakeRequest ? cacheKey : null, () => getUser({ customerUuid }), {
    revalidateOnFocus: false,
    onError
  });
  const isLoading = getSWRIsLoading(data, error, shouldMakeRequest);

  return { data, isLoading, error };
};

export default useGetUser;
