import { NonAuthenticatedUserService } from './LegacyUserService';
import handleError from './errorHandler';

const postVerificationEmail = async customerUuid => {
  try {
    const { data } = await NonAuthenticatedUserService.post(`/users/${customerUuid}/verification_email`);

    return data;
  } catch (error) {
    handleError(error, 'Error sending verification email');
  }
};

export default postVerificationEmail;
