import { ORGANISATIONS } from '@nm-utils-lib-web/organisations';

import handleError from '../../errorHandler';
import NonAuthenticatedUserService from '../NonAuthenticatedUserService';

const authOrgMap = {
  [ORGANISATIONS.NUTMEG_GBR_BIGBEAR]: 'nutmeg',
  [ORGANISATIONS.JOHN_LEWIS_FINANCE_GBR]: 'jlp'
};

const fetchOrganisationCode = source => {
  const isChaseIsaPromoUser = source === 'chase';

  if (isChaseIsaPromoUser) {
    return ORGANISATIONS.CHASE_GBR;
  }

  return authOrgMap[global.NutmegConfig.ORGANISATION || ORGANISATIONS.NUTMEG_GBR_BIGBEAR];
};

/** @type {import('./createUser.types').createUser} */
const createUser = async ({ email, password, affiliate, source }) => {
  try {
    const { data } = await NonAuthenticatedUserService.post('/create_user', {
      email,
      password,
      organisationCode: fetchOrganisationCode(source),
      affiliate: affiliate || undefined
    });

    return data;
  } catch (error) {
    handleError(error, 'Error creating user');
  }
};

export default createUser;
