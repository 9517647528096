import { STEPS } from '@nm-utils-lib-web/onboarding-journey-service';

export const backToNonInvestorQueryParam = {
  backTo: 'nonInvestor'
};

export const onboardingTrueQueryParam = {
  onboarding: 'true'
};

export const PERSONAL_AND_FINANCIAL_DETAILS_STEPS = [
  STEPS.FINANCIAL_DETAILS,
  STEPS.IDENTIFICATION_DETAILS,
  STEPS.JURISDICTION,
  STEPS.IDV,
  STEPS.TERMS_AND_CONDITIONS,
  STEPS.EMPLOYMENT
];
