import { organisationHeaderTranslations } from '@nm-ui-shared-lib-web/organisation-header/languages/nutmeg';
import { organisationFooterTranslations } from '@nm-ui-shared-lib-web/organisation-footer/languages/nutmeg';
import noMatchLanguage from '@nm-utils-lib-web/no-match/languages/nutmeg-gbr-bigbear.json';
import { nutmeg as jisaProfile } from '@nm-customer/jisa-profile/languages';
import { nutmeg as nutmegAuth } from '@nm-auth/password-reset/languages';

import common from './common.json';
import appDownloadPage from './appDownloadPage.json';
import nonInvestor from './nonInvestor.json';
import processingInfo from './processing-info.json';
import verificationFailed from './verification-failed.json';
import registerPage from './registerPage.json';
import registerPassword from './registerPassword.json';
import verifyEmail from './verifyEmail.json';

export default {
  ...jisaProfile,
  ...nutmegAuth,
  signup: {
    common: {
      ...common,
      ...organisationHeaderTranslations,
      ...organisationFooterTranslations,
      ...noMatchLanguage
    },
    appDownloadPage,
    nonInvestor,
    processingInfo,
    registerPage,
    registerPassword,
    verifyEmail,
    verificationFailed
  }
};
