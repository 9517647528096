import { useCallback } from 'react';
import { useGetCustomerDependantPersonalInformation } from '@nm-customer/common/hooks';
import useGetPots from '@nm-pot/common/hooks/useGetPots';
import useGetDrafts from '@nm-pot/common/hooks/useGetDrafts';
import { CREATE_POT } from '@nm-pot/common/constants/journeyTypes';
import { POT_STATUSES } from '@nm-pot/common/constants';

const useGetAllPots = ({ customerUuid, triggerError }) => {
  const {
    data: potData,
    isValidating: isLoadingPots,
    getPots
  } = useGetPots({
    customerUuid,
    statuses: [POT_STATUSES.SAVED],
    onError: triggerError
  });
  const {
    data: customerDrafts,
    isValidating: isLoadingDrafts,
    getDrafts
  } = useGetDrafts({
    customerUuid,
    journeyTypes: [CREATE_POT],
    onError: triggerError
  });
  const {
    jisaDependants,
    isLoading: isLoadingDependants,
    getCustomerDependantPersonalInformation
  } = useGetCustomerDependantPersonalInformation(customerUuid, {
    onError: triggerError,
    ignore404: true
  });
  const isLoadingAllPots = isLoadingPots || isLoadingDrafts || isLoadingDependants;
  const getAllPotData = useCallback(() => {
    getPots();
    getDrafts();
    getCustomerDependantPersonalInformation();
  }, [getPots, getDrafts, getCustomerDependantPersonalInformation]);

  return { customerDrafts, potData, jisaDependants, isLoadingAllPots, getDrafts, getAllPotData };
};

export default useGetAllPots;
