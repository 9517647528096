import React from 'react';
import { node } from 'prop-types';
import Align, { justifyContentRules, alignItemsRules } from '@nutkit/component-align';
import Section, { sectionTagNames } from '@nutkit/component-section';
import { isJohnLewisFinanceGBR } from '@nm-utils-lib-web/organisations';

import styles from './AdaptiveContainer.module.scss';

const AdaptiveContainer = ({ children }) => {
  const isJohnLewisFinance = isJohnLewisFinanceGBR();

  return (
    <Align alignItems={alignItemsRules.CENTER} justifyContent={justifyContentRules.CENTER} fullHeight>
      <div
        className={`${styles.adaptiveContainer} ${!isJohnLewisFinance && styles.nutmegAdaptiveContainer}`}
        data-qa="register-form-container"
      >
        <Section tagName={sectionTagNames.DIV}>{children}</Section>
      </div>
    </Align>
  );
};

AdaptiveContainer.propTypes = {
  children: node.isRequired
};

export default AdaptiveContainer;
