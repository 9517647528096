import * as WRAPPER_TYPES from '@nm-pot/common/constants/wrapperTypes';

import { getFirstEligibleDraft } from './getFirstEligibleDraft';

export const isDraftEligible = (draft, eligibleProducts, isEligible) => {
  const { wrapperType } = draft;

  if (!wrapperType) return;

  return wrapperType === WRAPPER_TYPES.GIA_ISA
    ? eligibleProducts.includes(WRAPPER_TYPES.GIA.toUpperCase()) === isEligible
    : eligibleProducts.includes(wrapperType.toUpperCase()) === isEligible;
};

export const filterDraftsByEligibility = (drafts, eligibleProducts, isEligible) =>
  drafts.filter(draft => isDraftEligible(draft, eligibleProducts, isEligible));

export const formatEligibilityData = (eligibilityData, drafts, jisaDependants) => {
  const eligibleProducts = eligibilityData?.wrappers;
  const eligibleDrafts = eligibleProducts && filterDraftsByEligibility(drafts, eligibleProducts, true);
  const ineligibleDrafts = eligibleProducts && filterDraftsByEligibility(drafts, eligibleProducts, false);
  const hasJisaDependants = jisaDependants?.length > 0;
  const isJISAEligible = eligibleProducts?.includes(WRAPPER_TYPES.JISA.toUpperCase());
  const hasEligibleDraftPots = eligibleDrafts?.length > 0 || (hasJisaDependants && isJISAEligible);
  const hasIneligibleDraftPots = ineligibleDrafts?.length > 0 || (hasJisaDependants && !isJISAEligible);
  const firstEligibleDraft = getFirstEligibleDraft({
    eligibleDrafts,
    hasJisaDependants,
    isJISAEligible,
    jisaDependants
  });

  return {
    firstEligibleDraft,
    eligibleProducts,
    eligibleDrafts,
    ineligibleDrafts,
    hasEligibleDraftPots,
    hasIneligibleDraftPots
  };
};
