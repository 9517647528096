import React from 'react';
import { func, string } from 'prop-types';
import { Route as ReactRoute } from 'react-router-dom';
import { MetaTitle } from '@nm-utils-lib-web/meta-title';
import { useTranslation } from '@nm-utils-lib-web/translations';

export const Route = ({ render, title, ...props }) => {
  const { t } = useTranslation();

  return (
    <>
      <MetaTitle title={t(title)} />
      <ReactRoute {...props} render={render} />
    </>
  );
};

Route.propTypes = {
  render: func.isRequired,
  title: string
};

Route.defaultProps = {
  title: 'global.common.organisation.appTitle'
};
