import React from 'react';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import { textSizes } from '@nutkit/component-text';
import { Trans } from '@nm-utils-lib-web/translations';

const TRANSLATION_NAMESPACE = 'signup.registerPage.registerForm';
//
const EmailVerificationNotification = () => (
  <Notification
    size={textSizes.XS}
    level={notificationLevels.INFORMATION}
    dismissable={false}
    aria-live="polite"
    data-qa="email-verification-notification"
    alternative
  >
    <Trans i18nKey={`${TRANSLATION_NAMESPACE}.emailVerificationNotification`} />
  </Notification>
);

export default EmailVerificationNotification;
