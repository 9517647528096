import React, { useEffect } from 'react';
import { Signup } from '@nm-utils-lib-web/routes';
import { setSegmentGroup, mapOrgIdToSegmentOrgGroup } from '@nm-utils-lib-web/analytics';
import { Switch } from 'react-router-dom';
import FlagsStatus from '@nm-utils-lib-web/flags/src/components/FlagsStatus';
import ErrorBoundary from '@nm-utils-lib-web/error-boundary';
import Notification, { notificationLevels } from '@nutkit/component-notification';
import { useTranslation } from '@nm-utils-lib-web/translations';

import VerifyEmailPage from '../VerifyEmailPage';
import { Route } from '../../containers/Route/Route';

import RegisterLayout from './RegisterLayout';
import RegisterForm from './RegisterForm';

// TODO: add back link to this...
const RegisterPage = () => {
  const [emailAddressToVerify, setEmailAddressToVerify] = React.useState(null);
  const { t } = useTranslation();

  const onSubmit = ({ email }) => {
    setEmailAddressToVerify(email);
  };

  /* Setting group here specifically before registration so onboarding events are grouped.
  We try to only set group after authentication to handle Chase users. In this case
  Chase users shouldn't be directed to the Register page during signup. */
  useEffect(() => {
    setSegmentGroup(mapOrgIdToSegmentOrgGroup(window.NutmegConfig.ORGANISATION));
  }, []);

  return (
    <FlagsStatus>
      <Switch>
        <Route
          exact
          path={Signup.VERIFY_EMAIL_PATH}
          key={Signup.VERIFY_EMAIL_PATH}
          render={() => <VerifyEmailPage emailAddressToVerify={emailAddressToVerify} />}
          data-qa="verify-email-path"
          title="signup.verifyEmail.metaTitle"
        />
        <Route
          exact
          path={Signup.REGISTER_PATH}
          key={Signup.REGISTER_PATH}
          render={() => (
            <RegisterLayout>
              <ErrorBoundary
                contextualDescription="[Register] Page failure"
                fallback={
                  <Notification level={notificationLevels.ERROR} dismissable={false} data-qa="register-page-error">
                    {t('signup.common.error.defaultText')}
                  </Notification>
                }
              >
                <RegisterForm onSubmit={onSubmit} />
              </ErrorBoundary>
            </RegisterLayout>
          )}
          data-qa="register-path"
          title="signup.registerPage.register.metaTitle"
        />
      </Switch>
    </FlagsStatus>
  );
};

export default RegisterPage;
